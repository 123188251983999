import { FC } from "react";
import styled from "@emotion/styled";

import { GoldenBasketDetailHeader } from "./GoldenBasketDetailHeader";
import { GoldenBasketDetailFooter } from "./GoldenBasketDetailFooter";
import { GoldenBasketDetailContent } from "./GoldenBasketDetailContent";

import { StatusPendingImage } from "../../ui-share";
import {
  NoResult,
  GoldenBasketStatusWrapper,
  StatusMessage,
} from "../../../ui-components/states";
import celebrationImage from "../../../assets/states/celebration.png";

import { Campaign } from "../../../share/campaigns/store";
import { State } from "../../../utils/store";
import { useAppSelector } from "../../../utils/hooks";

export const GoldenBasketDetail: FC<{
  statusPending: boolean;
  campaign: Campaign;
}> = ({ statusPending, campaign }) => {
  const { GBList: unfilteredGBList } = useAppSelector(
    (state: State) => state.goldenBasket
  );
  const taggedResults = unfilteredGBList.filter(
    (company) => company.isMatch !== null
  ).length;
  const goldenBasketFullyTagged =
    unfilteredGBList.length === taggedResults && unfilteredGBList.length > 0;

  const { toReview: toReviewSelected } = useAppSelector(
    (state: State) => state.goldenBasket.filters
  );
  const { selectedCompany } = useAppSelector(
    (state: State) => state.goldenBasket
  );

  const showRequestNewIteration = goldenBasketFullyTagged && toReviewSelected;

  const showIterationHasBeenRequested = campaign?.requests?.iterationRequested;

  return selectedCompany ? (
    <Container>
      <GoldenBasketDetailHeader company={selectedCompany} />
      <GoldenBasketDetailContent
        campaignUUID={campaign.uuid}
        company={selectedCompany}
      />
      <GoldenBasketDetailFooter campaignUUID={campaign.uuid} />
    </Container>
  ) : statusPending ? (
    <GoldenBasketStatusWrapper>
      <StatusPendingImage />
      <StatusMessage>
        The results are being reviewed by your Novable Agent, you will be
        notified when results appear on this page.
      </StatusMessage>
    </GoldenBasketStatusWrapper>
  ) : showIterationHasBeenRequested ? (
    <GoldenBasketStatusWrapper>
      <StatusPendingImage />
      <StatusMessage>
        A Delivery has been requested. The results are being reviewed by your
        Novable Agent, you will be notified when results appear on this page.
      </StatusMessage>
    </GoldenBasketStatusWrapper>
  ) : showRequestNewIteration ? (
    <GoldenBasketStatusWrapper>
      <CelebrationImage />
      <StatusMessage>
        Congratulations, you tagged all the Companies in your Golden Basket.
        Request a Delivery to get more Companies or create another campaign to
        continue exploring
      </StatusMessage>
    </GoldenBasketStatusWrapper>
  ) : (
    <NoResult>
      <StatusMessage>No results found</StatusMessage>
    </NoResult>
  );
};

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 5rem 5rem 0 5rem;

  -webkit-animation: scale-up-center 0.3s cubic-bezier(0.26, 0.86, 0.36, 1) both;
  animation: scale-up-center 0.3s cubic-bezier(0.26, 0.86, 0.36, 1) both;
`;

const CelebrationImage = styled.div`
  height: 32rem;
  width: 40rem;
  background-image: url(${celebrationImage});
  background-size: 100% 100%;
  background-repeat: no-repeat;
`;
