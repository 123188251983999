import { Result } from "antd";

import { State } from "../../utils/store";
import { useAppSelector } from "../../utils/hooks";
import { isClientPremium } from "../../helpers";

export const CampaignSubmitted = () => {
  // Previous screen ends up at the bottom of the page, we need to scroll to show the Result
  // on top of everything
  window.scrollTo({ top: 0 });

  const { selectedClient } = useAppSelector((state: State) => state.clients);

  const isPaidClient = isClientPremium(selectedClient);
  const ctaLink = isPaidClient
    ? "https://gonovable.com/meetings/thibaut-jacobs/campaign-review-with-a-novable-agent-"
    : "https://meetings.hubspot.com/caspar-van-rijn/first-campaign";

  return (
    <div>
      <Result
        status="success"
        title="Your Campaign has been successfully published"
        subTitle="We’re on it, you will receive news from us very soon."
      />
      <div>
        <h2 style={{ margin: "auto", width: "80%", textAlign: "center" }}>
          We’ll contact you shortly to discuss the results. <br />
          We suggest that you already book a moment that suits you.
        </h2>
        <iframe
          title={"hubspot"}
          style={{
            width: "80%",
            height: "700px",
            border: "none",
            marginLeft: "10%",
          }}
          src={ctaLink}
        />
      </div>
    </div>
  );
};
