import { FC } from "react";
import styled from "@emotion/styled";

import { Section, SectionContent as BaseSectionContent } from "../../ui-share";
import { CommentLabel } from ".";

export const AgentComment: FC<{ comment: string }> = ({ comment }) => {
  return (
    <Section>
      <CommentLabel>Your Novable Agent says</CommentLabel>

      <SectionContent
        dangerouslySetInnerHTML={{ __html: comment }}
      ></SectionContent>
    </Section>
  );
};

const SectionContent = styled(BaseSectionContent)`
  padding: 1.3rem;
  border-radius: var(--border-radius);
  background-color: var(--primary-color-light);
  font-size: var(--gb-content-font-size);
  margin-bottom: 1rem;

  & p {
    margin: 0;
  }
`;
