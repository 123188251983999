import styled from "@emotion/styled";

import { Tag } from "../../../../../ui-components/Tag";

import informationIcon from "../../../../../assets/icons/information.svg";

export { ClearbitAttribution } from "./ClearbitAttribution";
export { CrunchbaseAttribution } from "./CrunchbaseAttribution";
export { ExtraTopicsPopover } from "./ExtraTopicsPopover";
export { FlaticonAttribution } from "./FlaticonAttribution";
export { FundingInformation } from "./FundingInformation";
export { GeneralInformation } from "./GeneralInformation";
export { News } from "./News";
export { Snapshot } from "./Snapshot";
export { Topics } from "./Topics";
export { Tags } from "./Tags";
export { TwitterFeed } from "./TwitterFeed";

// https://ant.design/components/tag/#When-To-Use
export const TAG_HIGHLIGHT_COLOR = "var(--match-green-light)";
export const TAG_HIGHLIGHT_TOOLTIP_TEXT =
  "This topic is highlighted because it's one of the topics that match your briefing";

export const Attribution = styled.a`
  font-size: 1.2rem;
  line-height: 1.3rem;
  opacity: 0.8;
  color: var(--font-color-campaign-stats);
`;

export const PoweredText = styled.p`
  font-size: 1.2rem;
  color: var(--font-color-campaign-stats);
`;

export const PowerededBy = styled.div`
  margin-bottom: 2rem;
`;

export const Content = styled.div`
  // display:flex;
  // flex-direction: column;
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
  flex: auto;
  overflow: scroll;
  background: var(--background-color-primary);
  padding: 3rem;
  font-size: var(--gb-content-font-size);
`;

export const NonClickableTag = styled(Tag)`
  cursor: default;
  font-size: 1.2rem;
  line-height: 2rem;
`;

export const HighlightedNonClickableTag = styled(NonClickableTag)`
  color: var(--highlighted-text-color);
  border: var(--match-green-border);
`;

export const InfoIcon = styled.div`
  background: url(${informationIcon}) no-repeat;
  width: 1.4rem;
  height: 1.4rem;
  margin-right: 0.933rem;
`;
