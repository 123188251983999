import styled from "@emotion/styled";
import { useEffect, useState, useCallback, useRef } from "react";
import { useFormikContext } from "formik";
import { useNavigate } from "react-router";

import { Modal } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { getReadyCampaignData, SecondaryButton } from "../NewCampaignView";
import {
  errorNotification,
  successNotification,
} from "../../ui-components/Notification";

import { saveDraftCampaign, deleteDraftCampaign } from "../store";
import { State } from "../../utils/store";
import { useAppDispatch, useAppSelector } from "../../utils/hooks";

import { Segment } from "../../utils/Segment";
import { debounce, isEqual } from "lodash";

const { confirm } = Modal;

interface ManualSaveDraftButtonsProps {
  disableAutoSave?: boolean;
}

export const ManualSaveDraftButtons: React.FC<ManualSaveDraftButtonsProps> = ({ 
  disableAutoSave = false,
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const formik = useFormikContext();
  const lastSavedValuesRef = useRef(formik.values);
  const formikValuesLoaded = useRef(false);
  const firstMount = useRef(false);

  // To avoid displaying notifications from the autosave
  const [displaySaveNotification, setDisplaySaveNotification] = useState(false);
  const {
    success: saveDraftSuccess,
    message: saveDraftMessage,
    loading: saveDraftLoading,
  } = useAppSelector((state: State) => state.newCampaign.saveDraftCampaign);
  const {
    success: deleteDraftSuccess,
    message: deleteDraftMessage,
    loading: deleteDraftLoading,
  } = useAppSelector((state: State) => state.newCampaign.deleteDraftCampaign);
  const { selectedClient } = useAppSelector((state: State) => state.clients);
  const { user } = useAppSelector((state: State) => state.auth);
  // Draft name
  const { name } = useAppSelector(
    (state: State) => state.newCampaign.draftCampaign
  );

  const { clusters } = useAppSelector((state: State) => state.newCampaign);

  interface CampaignFormValues {
    name: string;
    strategyPlainText?: string;
    keywords?: string[];
  }

  useEffect(() => {
    if (!firstMount.current) {
      firstMount.current = true;
      lastSavedValuesRef.current = formik.values;
    }
  }
  , []);

  const autoSaveDraft = useCallback(
    debounce(() => {
      const formValues: CampaignFormValues = formik.values as CampaignFormValues;

      if (disableAutoSave) return;

      if (!firstMount.current) {
        firstMount.current = true;
        lastSavedValuesRef.current = formik.values;
        return;
      }

      if (isEqual(formik.values, lastSavedValuesRef.current)) {
        return;
      }

      if (!formikValuesLoaded.current) {
        formikValuesLoaded.current = true;
        return;
      }

      if (!name || !selectedClient || name.includes("Campaign Title") && formValues.strategyPlainText=="") return;
        const readyData = getReadyCampaignData(formik.values, selectedClient, name, clusters);
        dispatch(saveDraftCampaign(readyData));
        lastSavedValuesRef.current = formik.values;
    }, 5*1000), 
    [formik.values, name, selectedClient, clusters, dispatch]
  );

  useEffect(() => {
    autoSaveDraft();
  }, [autoSaveDraft]);

  useEffect(
    () => {
      if (saveDraftSuccess && displaySaveNotification) {
        setDisplaySaveNotification(false);
        successNotification("Draft saved");
      } else if (
        !saveDraftSuccess &&
        saveDraftMessage &&
        displaySaveNotification
      ) {
        errorNotification(saveDraftMessage);
        setDisplaySaveNotification(false);
      }
    },
    // Do not add displaySaveNotification to dependencies
    [dispatch, saveDraftSuccess, saveDraftMessage] // eslint-disable-line
  );

  useEffect(() => {
    // Success notification would never be shown from this component. It has been
    // placed in the DashboardView
    if (!deleteDraftSuccess && deleteDraftMessage)
      errorNotification(deleteDraftMessage);
  }, [dispatch, deleteDraftSuccess, deleteDraftMessage]);

  const saveDraft = () => {
    if (!name) {
      errorNotification("Name cannot be empty");
      return;
    }
    if (!selectedClient) return;
    setDisplaySaveNotification(true);
    const readyData = getReadyCampaignData(
      formik.values,
      selectedClient,
      name,
      clusters
    );
    dispatch(saveDraftCampaign(readyData));
    Segment.analytics?.track("save-draft-button", {
      campaignUUID: readyData.uuid,
      client: selectedClient?.name,
      user: `${user?.firstName} ${user?.lastName}`,
    });
  };

  const showConfirmDelete = () => {
    confirm({
      title: "You will lose all progress, are you sure you want to delete the draft?",
      icon: <ExclamationCircleOutlined />,
      onOk() {
        deleteDraftAndRedirect();
      },
      okButtonProps: {
        style: {
          backgroundColor: 'var(--primary-color)',
          borderColor: 'var(--primary-color)',
          color: 'var(--white)',
        },
      },
      cancelButtonProps: {
        style: {
          backgroundColor: 'var(--primary-color)',
          borderColor: 'var(--primary-color)',
          color: 'var(--white)',
        },
      },
      okText: "Delete",
      cancelText: "Cancel",
    });
  };

  const deleteDraftAndRedirect = () => {
    if (!selectedClient) return;
    let currentValues: any = formik.values;
    const { uuid } = currentValues;
    dispatch(
      deleteDraftCampaign({ clientUUID: selectedClient.uuid, uuid: uuid })
    );
    navigate(`/${selectedClient.slug}/dashboard`);
  };

  return (
    <>
      <SecondaryButton 
          onClick={showConfirmDelete} 
          disabled={deleteDraftLoading}>
          Delete draft
      </SecondaryButton>
    </>
  );
};
