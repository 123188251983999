import { FC, useCallback, useEffect, useState } from "react";
import styled from "@emotion/styled";

import confetti from "canvas-confetti";
import { notification } from "antd";
import { CompanyCard } from "./list-components/CompanyCard";
import { RequestNewIterationButton } from "./list-components/RequestNewIterationButton";
import { ResultCount } from "./ui-share/ResultCount";
import { CenteredSpinner } from "../../../ui-components/CenteredSpinner";
import { CenteredErrorMessage } from "../../../ui-components/CenteredErrorMessage";
import { StatusMessage } from "../../../ui-components/states";

import cookieIcon from "../../../assets/icons/cookie-svgrepo-com.svg";

import { Campaign } from "../../../share/campaigns/store";
import {
  fetchSelectedCompany,
  setFilter,
  filteredGBListSelector,
  setInitialCompanyDetails,
  setIsGBCandidateForConfettiInitialState,
} from "../store";
import { Filter as EFilter } from "../store";
import { State } from "../../../utils/store";

import { useAppDispatch, useAppSelector } from "../../../utils/hooks";
import { Segment } from "../../../utils/Segment";

interface GoldenBasketCompanyListProps {
  canResetFilter: boolean;
  campaign: Campaign;
  handleDisplayTableView: (shouldDisplay: boolean) => void;
}

export const GoldenBasketCompanyList: FC<GoldenBasketCompanyListProps> = ({
  canResetFilter,
  campaign,
  handleDisplayTableView,
}) => {
  const dispatch = useAppDispatch();
  const GBList = useAppSelector(filteredGBListSelector);
  const [hasResetFilterToMatch, setHasResetFilterToMatch] = useState(false);

  const {
    loading: loadingGBList,
    error: errorGBList,
    success: successGBList,
  } = useAppSelector((state: State) => state.goldenBasket.getGBList);
  const { GBList: unfilteredGBList } = useAppSelector(
    (state: State) => state.goldenBasket
  );
  const { selectedClient } = useAppSelector((state: State) => state.clients);
  const taggedResults = unfilteredGBList.filter(
    (company) => company.isMatch !== null
  ).length;
  const goldenBasketFullyTagged =
    unfilteredGBList.length === taggedResults && unfilteredGBList.length > 0;

  const {
    toReview: toReviewSelected,
    match: matchSelected,
    miss: missSelected,
    favourite: favouriteSelected,
  } = useAppSelector((state: State) => state.goldenBasket.filters);

  const { isGBCandidateForConfetti } = useAppSelector(
    (state: State) => state.goldenBasket
  );

  const handleFetchSelectedCompanyDetails = (uuid: string) => {
    dispatch(setInitialCompanyDetails());
    dispatch(fetchSelectedCompany(uuid));

    let filteredCompanies = unfilteredGBList.filter(
      (company) => company.uuid === uuid
    );
    if (filteredCompanies.length === 1) {
      Segment.analytics?.track("open company detail", {
        source: "golden-basket-view",
        companyUUID: filteredCompanies[0].company.uuid,
      });
    }
  };

  function handleSetFilter(filter: string) {
    dispatch(setInitialCompanyDetails());
    dispatch(setFilter(filter));
  }

  const [confettiHasBeenShown, setConfettiHasBeenShown] = useState(false);

  const showCongratulations = useCallback(() => {
    confetti({
      particleCount: 100,
      angle: 55,
      spread: 70,
      origin: { y: 0.6 },
    });
    confetti({
      particleCount: 100,
      spread: 70,
      angle: 90,
      origin: { y: 0.6 },
    });
    confetti({
      particleCount: 100,
      spread: 70,
      angle: 125,
      origin: { y: 0.6 },
    });
    notification.open({
      message: "Well done!",
      description: (
        <div>
          <p>
            What now? If you want more results, you can{" "}
            <strong>request a new Delivery</strong>. You can also{" "}
            <a href={`/${selectedClient?.slug}/new-campaign`}>
              start a new campaign
            </a>{" "}
            if your needs are different.
          </p>
          <CookieWrapper>
            <span>You can also go grab a cookie, you've earned it!</span>
            <Cookie />
          </CookieWrapper>
        </div>
      ),
      placement: "top",
      duration: 7,
    });
  }, [selectedClient]);

  useEffect(() => {
    if (
      isGBCandidateForConfetti &&
      !confettiHasBeenShown &&
      unfilteredGBList.filter((company) => company.isMatch === null).length ===
        0
    ) {
      showCongratulations();
      setConfettiHasBeenShown(true);
    }
  }, [
    unfilteredGBList,
    isGBCandidateForConfetti,
    confettiHasBeenShown,
    showCongratulations,
  ]);

  /*
    Business logic:
      If a GB has been loaded, and has no results on the To-Review filter
      Switch to Match filter
    
    The condition for this reset is as follows:
    - The GB List has loaded
    - The to-review filter is the selected filter
    - There are no results
    - It can only happen once (hasResetFilterToMatch)
    - The user did not click on a specific filter from the dashboard view (on campaign card)
  */
  useEffect(() => {
    if (
      successGBList &&
      toReviewSelected &&
      GBList.length === 0 &&
      !hasResetFilterToMatch &&
      canResetFilter
    ) {
      handleSetFilter(EFilter.MATCH);
      setHasResetFilterToMatch(true);
    }
  }, [successGBList]); //eslint-disable-line

  useEffect(() => {
    return () => {
      setHasResetFilterToMatch(false);
    };
  });

  // Needed to not show again the confetti+congratulatory message when a user
  // switches between golden basket, insights, and home tabs
  useEffect(() => {
    return () => {
      dispatch(setIsGBCandidateForConfettiInitialState());
    };
  }, [dispatch]);

  const statusPending = campaign.statusPending;
  const iterationHasBeenRequested = campaign?.requests?.iterationRequested;

  const successGetGBList = !loadingGBList && !errorGBList && successGBList;
  const successWithResults = successGetGBList && GBList.length !== 0;
  const successWithoutResults = successGetGBList && GBList.length === 0;
  const gbStatusPending = successWithoutResults && statusPending;

  const noResults = successGetGBList && unfilteredGBList.length === 0;
  // Request a new iteration only when To Review filter is selected
  const showRequestNewIteration =
    successGetGBList &&
    goldenBasketFullyTagged &&
    toReviewSelected &&
    !iterationHasBeenRequested;
  const showIterationHasBeenRequested =
    successGetGBList &&
    goldenBasketFullyTagged &&
    toReviewSelected &&
    iterationHasBeenRequested;
  const noCompaniesInSelectedFilter =
    successWithoutResults && !goldenBasketFullyTagged;

  return (
    <List>
      <ResultCount
        handleDisplayTableView={handleDisplayTableView}
        campaignUUID={campaign.uuid}
      >
        {GBList.length} / {unfilteredGBList.length}
      </ResultCount>
      <FiltersSection>
        <Filters>
          <Filter
            selected={toReviewSelected}
            onClick={() => handleSetFilter(EFilter.TOREVIEW)}
          >
            <FilterLabel selected={toReviewSelected}>Review</FilterLabel>
          </Filter>
          <Filter
            selected={matchSelected}
            onClick={() => handleSetFilter(EFilter.MATCH)}
          >
            <FilterLabel selected={matchSelected}>Match</FilterLabel>
          </Filter>
          <Filter
            selected={missSelected}
            onClick={() => handleSetFilter(EFilter.MISS)}
          >
            <FilterLabel selected={missSelected}>Miss</FilterLabel>
          </Filter>
          {/* <Filter
            selected={favouriteSelected}
            onClick={() => handleSetFilter(EFilter.FAVOURITE)}
          > */}
            {/* <FavouriteIcon selected={favouriteSelected} /> */}
          {/* </Filter> */}
        </Filters>
      </FiltersSection>
      <GBListContainer>
        {errorGBList ? (
          <CenteredErrorMessage>
            <StatusMessage>
              There was an error fetching the list of companies. {errorGBList}{" "}
            </StatusMessage>
          </CenteredErrorMessage>
        ) : loadingGBList ? (
          <CenteredSpinner size="large" />
        ) : successWithResults ? (
          GBList.map((company) => (
            <CompanyCard
              key={company.uuid}
              gbCompany={company}
              onClick={handleFetchSelectedCompanyDetails}
            />
          ))
        ) : gbStatusPending ? (
          <CenteredErrorMessage>
            <StatusMessage>
              The campaign is pending action from our Novable agents
            </StatusMessage>
          </CenteredErrorMessage>
        ) : showRequestNewIteration ? (
          <CenteredErrorMessage>
            <RequestNewIterationButton campaign={campaign} />
          </CenteredErrorMessage>
        ) : showIterationHasBeenRequested ? (
          <CenteredErrorMessage>
            <StatusMessage>
              A Delivery has been requested. You will be notified when new
              Companies are uploaded
            </StatusMessage>
          </CenteredErrorMessage>
        ) : noResults ? (
          <CenteredErrorMessage>
            <StatusMessage>
              Looks like this campaign has no results
            </StatusMessage>
          </CenteredErrorMessage>
        ) : (
          noCompaniesInSelectedFilter && (
            <CenteredErrorMessage>
              <StatusMessage>No Companies in the selected filter</StatusMessage>
            </CenteredErrorMessage>
          )
        )}
      </GBListContainer>
    </List>
  );
};

const List = styled.div`
  background: var(--campaign-stats-background);
  box-shadow: var(--gb-list-container-shadow);
  position: sticky;
  top: 8rem;
  height: calc(100vh - 8rem);
`;

const FilterLabel = styled.p<{ selected: boolean }>`
  margin: 0;
  font-size: 1.4rem;
  line-height: 1.641rem;
  color: ${(props) =>
    props.selected ? "var(--white)" : "var(--font-color-default)"};
`;

const Filter = styled.div<{ selected: boolean }>`
  width: 28.3%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.8rem;
  background-color: ${(props) =>
    props.selected
      ? "var(--filter-selected-background)"
      : "var(--filter-background)"};
  text-align: center;
  cursor: pointer;

  &:last-of-type {
    width: 15%;
  }
`;

const Filters = styled.div`
  width: 100%;

  display: flex;
  justify-content: center;

  padding: 0.2rem;
  border: var(--border-filters);
  border-radius: 0.6rem;

  div:first-of-type {
    border-radius: 0.4rem 0 0 0.4rem;
  }

  div:last-child {
    border-radius: 0 0.4rem 0.4rem 0;
  }
`;

const FiltersSection = styled.div`
  padding: 1.2rem 1.6rem;
  width: 100%;
`;

const GBListContainer = styled.div`
  // Total height minus results header minus filter
  height: calc(100% - 5.2rem - 10rem);
  overflow-y: scroll;
`;

const CookieWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;
const Cookie = styled.div`
  background: url(${cookieIcon});
  width: 2rem;
  height: 2rem;
  background-size: 100% 100%;
  background-repeat: no-repeat;
`;
