import { FC } from "react";
import styled from "@emotion/styled";
import { BaseCompany } from "../../store";

import facebookIcon from "../../../../assets/icons/facebook.svg";
import twitterIcon from "../../../../assets/icons/twitter.svg";
import linkedinIcon from "../../../../assets/icons/linkedin.svg";
import websiteIcon from "../../../../assets/icons/website.svg";
import googleMapsIcon from "../../../../assets/icons/google-maps-icon.svg";

import { addUTMSource, openLinkInNewTabAndTrack } from "../../../../helpers";

interface SocialMediaProps {
  company: BaseCompany;
  isCompanyDetail: boolean;
}

export const SocialMedia: FC<SocialMediaProps> = ({
  company,
  isCompanyDetail,
}) => {
  return (
    <IconsContainer>
      {company.url && (
        <a
          href={addUTMSource(company.url)}
          target="_blank"
          rel="noopener noreferrer"
          aria-label="website"
          onClick={(event) =>
            openLinkInNewTabAndTrack(
              event,
              addUTMSource(company.url || ""),
              "open-company-website",
              { source: "company-card" }
            )
          }
        >
          <Website />
        </a>
      )}
      {company.linkedin && (
        <a
          href={company.linkedin}
          target="_blank"
          rel="noopener noreferrer"
          aria-label="linkedin"
          onClick={(event) =>
            openLinkInNewTabAndTrack(
              event,
              addUTMSource(company.linkedin || ""),
              "open-company-linkedin",
              { source: "company-card" }
            )
          }
        >
          <LinkedIn />
        </a>
      )}
      {isCompanyDetail && company.twitter && (
        <a
          href={company.twitter}
          target="_blank"
          rel="noopener noreferrer"
          aria-label="twitter"
          onClick={(event) =>
            openLinkInNewTabAndTrack(
              event,
              addUTMSource(company.twitter || ""),
              "open-company-twitter",
              { source: "company-card" }
            )
          }
        >
          <Twitter />
        </a>
      )}
      {isCompanyDetail && company.facebook && (
        <a
          href={company.facebook}
          target="_blank"
          rel="noopener noreferrer"
          aria-label="facebook"
          onClick={(event) =>
            openLinkInNewTabAndTrack(
              event,
              addUTMSource(company.facebook || ""),
              "open-company-facebook",
              { source: "company-card" }
            )
          }
        >
          <Facebook />
        </a>
      )}
      {isCompanyDetail && company.address && (
        <a
          href={`http://maps.google.com/maps?q=${encodeURIComponent(
            company.address
          )}`}
          target="_blank"
          rel="noopener noreferrer"
          aria-label="google maps"
          onClick={(event) =>
            openLinkInNewTabAndTrack(
              event,
              `http://maps.google.com/maps?q=${encodeURIComponent(
                company.address || ""
              )}`,
              "open-company-maps",
              { source: "company-card" }
            )
          }
        >
          <GoogleMaps />
        </a>
      )}
    </IconsContainer>
  );
};

const IconsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const BaseIcon = styled.div`
  background-size: 100% 100%;
  background-repeat: no-repeat;
  margin-right: 1.2rem;
`;

const Facebook = styled(BaseIcon)`
  background: url(${facebookIcon});
  width: 0.667rem;
  height: 1.113rem;
`;

const Twitter = styled(BaseIcon)`
  background: url(${twitterIcon});
  width: 1.333rem;
  height: 1rem;
`;
const LinkedIn = styled(BaseIcon)`
  background: url(${linkedinIcon});
  width: 1.2rem;
  height: 1.167rem;
`;
const Website = styled(BaseIcon)`
  background: url(${websiteIcon});
  width: 1.333rem;
  height: 1.333rem;
`;
const GoogleMaps = styled(BaseIcon)`
  background: url(${googleMapsIcon});
  width: 1.267rem;
  height: 1.467rem;
`;
